<template>
  <div id="CA" :style="{ backgroundImage: 'url(' + globalConfig.RESOURCE_PREFIX + '/MBL_E-handbook_Design/2nd_layer/2nd_layer_CA.jpg)' }">
    <a id="imagewheel" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','wheel')"></a>
    <a id="imagechair" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','chair')"></a>
    <a id="imagewindow" href="javascript:void(0);" @click.stop="redirectTo('SystemsDetail','window')"></a>
    <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "ComfortActuators",
  data: () => ({
    loading: false,
    items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' }
      ],
      offset: true,
  }),
  mounted(){
  },
  computed: {
  },
  methods: {
    redirectTo(path,subSystem) {
      this.$router.push({
        path,
        query: {
          subSysname: subSystem
        },
      });
    },
  },
};
</script>

<style lang="sass">
.home
  height: calc(100% - 0px)

#CA
  position: relative
  height: 100%
  background-position: 0px 0px !important
  background-size:100%  110%
  max-width: 100% !important
h4
  font-size: 20px
  line-height: 1.25
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #005691
  font-style: normal
p
  font-size: 16px !important
  line-height: 1.5 !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif !important
#imagewheel
  position: absolute
  left: 30%
  top: calc(90% - 0px)
  width: 42%
  height: 6%
  z-index: 999999999 !important
#imagechair
  position: absolute
  left: 36%
  top: calc(30% - 0px)
  width: 25%
  height: 7%
  z-index: 999999999 !important
#imagewindow
  position: absolute
  left: 6%
  top: calc(38% - 0px)
  width: 25%
  height: 7%
  z-index: 999999999 !important
</style>
